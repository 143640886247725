import { useSkinType } from './useSkinType';

import { SkinTypeContainer, RightSideContainer } from './style';

import { CoordinateChart } from './components/CoordinateChart';
import { FaceImage } from './components/FaceImage';
import { SkinTypeRate } from './components/SkinTypeRate';
import { RecommendationTable } from './components/RecommendationTable';
import { ValuesTable } from './components/ValuesTable';

export const SkinType = () => {
  const { isRecommendation } = useSkinType();

  return (
    <SkinTypeContainer>
      <CoordinateChart />
      <RightSideContainer>
        <FaceImage />
        <SkinTypeRate />
        {isRecommendation ? <RecommendationTable /> : <ValuesTable />}
      </RightSideContainer>
    </SkinTypeContainer>
  );
};
