import { useAppDataContext } from 'utils/context';
import { useTranslation } from 'react-i18next';
import { getRecommendation } from 'lib/getRecommendation';

export const useRecommendationTable = () => {
  const { t } = useTranslation();
  const {
    appData: {
      results: { sebumT, sebumU, moistureT, moistureU },
    },
  } = useAppDataContext();

  const tZone = t(
    getRecommendation({
      zone: 't',
      moisture: moistureT?.value,
      sebum: sebumT?.value,
    })
  );
  const uZone = t(
    getRecommendation({
      zone: 'u',
      moisture: moistureU?.value,
      sebum: sebumU?.value,
    })
  );

  const tZoneTitle = t('t_zone');
  const uZoneTitle = t('u_zone');

  return { tZone, uZone, tZoneTitle, uZoneTitle };
};
