import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const Table = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr 1fr;
`;

const TableCell = styled.span`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 18px;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const TZoneTitle = styled(TableCell)`
  border-right: 1px dashed black;
  border-bottom: 1px dashed black;
`;

export const TZoneVale = styled(TableCell)`
  border-bottom: 1px dashed black;
`;

export const UZoneTitle = styled(TableCell)`
  border-right: 1px dashed black;
`;

export const UZoneVale = styled(TableCell)``;
