import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const CoordinateChartContainer = styled.div<{
  abscissaTitle: string;
  ordinateTitle: string;
}>`
  position: relative;
  width: 460px;
  height: 460px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 70% 10% 20%;
  border-left: 5px solid var(--color-main);
  border-bottom: 5px solid var(--color-main);

  @media ${breakpoints.mobile} {
    width: calc(460px / 2);
    height: calc(460px / 2);
  }
`;

export const AbscissaTitles = styled.div`
  position: absolute;
  top: 220px;
  right: 265px;
  width: 420px;
  transform: rotate(-90deg);
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  @media ${breakpoints.mobile} {
    top: 110px;
    right: 140px;
    width: calc(420px / 2);
    font-size: 12px;
  }
`;

export const OrdinateTitles = styled.div`
  position: absolute;
  left: 20px;
  bottom: -30px;
  display: flex;
  justify-content: space-between;
  width: 420px;

  @media ${breakpoints.mobile} {
    left: 0;
    bottom: -30px;
    width: calc(420px / 2);
    font-size: 12px;
  }
`;

export const MainTitle = styled.span`
  font-size: 18px;
  font-weight: 600;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const ChartFiller = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px dashed var(--color-main);
  border-right: 1px dashed var(--color-main);
`;

export const OptimalChartFiller = styled(ChartFiller)`
  background-color: var(--color-main);
  opacity: 0.3;
`;

const Zone = styled.div<{ width?: number; height?: number }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${(props) => `${props.width}%`};
  height: ${(props) => `${props.height}%`};
  border-top: 2px solid;
  border-right: 2px solid;
`;

export const UZone = styled(Zone)`
  border-color: #4ebf12;
`;

export const TZone = styled(Zone)`
  border-color: #fca65c;
`;

const Point = styled.div`
  position: absolute;
  top: -17px;
  right: -17px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: white;
  z-index: 2;

  @media ${breakpoints.mobile} {
    width: calc(35px / 2);
    height: calc(35px / 2);
    top: calc(-17px / 2);
    right: calc(-17px / 2);
  }
`;

export const UPoint = styled(Point)`
  background-color: #4ebf12;
`;

export const TPoint = styled(Point)`
  background-color: #fca65c;
`;
