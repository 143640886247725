import type { ProductRecommendationResponse } from 'types/api';

import axios from 'axios';

export const fetchProductRecommendation = async ({
  hairConditionId,
  scalpConditionId,
}: {
  hairConditionId: number;
  scalpConditionId: number;
}) => {
  const {
    data: { Products, Treatments },
  } = await axios.get<ProductRecommendationResponse>(
    `https://v2-api.chowis.cloud:3336/protrematrix/get/protre/${hairConditionId}/${scalpConditionId}`
  );

  return { Products, Treatments };
};
