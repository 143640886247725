import { MeasurementButtonsContainer, MeasurementButton } from './style';

interface MeasurementButtonsProps {
  images: any[];
  currentImageIdx: number;
  onMeasurementButtonClick: (idx: number) => void;
}

export const MeasurementButtons = ({
  images,
  currentImageIdx,
  onMeasurementButtonClick,
}: MeasurementButtonsProps) => {
  return (
    <MeasurementButtonsContainer>
      {images.map((image, idx) => (
        <MeasurementButton
          key={image.measurementText}
          onClick={() => onMeasurementButtonClick(idx)}
          disabled={idx === currentImageIdx}
        >
          {image.measurementText}
        </MeasurementButton>
      ))}
    </MeasurementButtonsContainer>
  );
};
