import { useBarGraphs } from './useBarGraphs';
import { BarGraphsContainer } from './style';

import { BarGraph } from './components/BarGraph';
import { ThicknessBarGraph } from './components/ThicknessBarGraph';

export const BarGraphs = () => {
  const { barGraphs } = useBarGraphs();

  return (
    <BarGraphsContainer>
      {barGraphs.map((result) => {
        const { measurement } = result;

        return measurement === 'thickness' ? (
          <ThicknessBarGraph key={result.measurement} />
        ) : (
          <BarGraph
            key={result.measurement}
            {...result}
          />
        );
      })}
    </BarGraphsContainer>
  );
};
