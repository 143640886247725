import { createContext, useContext, useState, useEffect } from "react";
import { AppData, FormatedResults } from "types/app";
import { CustomizationResponse } from "types/api";
import { fetchAppData } from "api/fetchAppData";
import i18n from "./i18n";
import { APP_CUSTOMIZATION } from "constants/dict";

interface AppDataContextInterface {
  appData: AppData;
  setAppData: React.Dispatch<React.SetStateAction<AppData>>;
  isLoading: boolean;
}

const initialAppData: AppData = {
  name: "",
  surname: "",
  gender: "male",
  date: "",
  time: "",
  birth: "",
  results: {} as FormatedResults,
  barGraphs: [],
  radars: [],
  lang: "en",
  appName: "dermobella-skin",
  customization: {} as CustomizationResponse,
  consultant_company_id: "",
};

const initialContextValue = {
  isLoading: true,
  appData: initialAppData,
  setAppData: () => {},
};

const appDataContext =
  createContext<AppDataContextInterface>(initialContextValue);

export const useAppDataContext = () => useContext(appDataContext);

export function AppDataContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [appData, setAppData] = useState(initialAppData);
  const [isLoading, setIsLoading] = useState(true);

  const contextValue = { appData, setAppData, isLoading };

  useEffect(() => {
    fetchAppData()
      .then((data) => {
        if (data.lang) {
          i18n.changeLanguage(data.lang);
        }

        document.documentElement.style.setProperty(
          "--color-main",
          APP_CUSTOMIZATION[data.appName].color,
        );

        if (data.appName === "loreal-hair") {
          const link = document.querySelector('link[rel="icon"]');
          link?.setAttribute("href", "loreal-favicon.ico");
        }

        setAppData(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <appDataContext.Provider value={contextValue}>
      {children}
    </appDataContext.Provider>
  );
}
