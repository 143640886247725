interface GetRecommendaitonArgs {
  zone: 't' | 'u';
  moisture: number;
  sebum: number;
}

export const getRecommendation = ({
  zone,
  moisture,
  sebum,
}: GetRecommendaitonArgs) => {
  let recommendation = '';

  switch (zone) {
    case 't': {
      if (moisture >= 0 && moisture <= 50) {
        if (sebum >= 0 && sebum <= 19) {
          recommendation = 'skintype_desc_advice_1';
        } else if (sebum >= 20 && sebum <= 30) {
          recommendation = 'skintype_desc_advice_2';
        } else if (sebum >= 31 && sebum <= 84) {
          recommendation = 'skintype_desc_advice_3';
        } else if (sebum > 84) {
          recommendation = 'skintype_desc_advice_4';
        }
      } else if (moisture > 50) {
        if (sebum >= 0 && sebum <= 19) {
          recommendation = 'skintype_desc_advice_8';
        } else if (sebum >= 20 && sebum <= 30) {
          recommendation = 'skintype_desc_advice_7';
        } else if (sebum >= 31 && sebum <= 84) {
          recommendation = 'skintype_desc_advice_6';
        } else if (sebum > 84) {
          recommendation = 'skintype_desc_advice_5';
        }
      }
      break;
    }

    case 'u': {
      if (moisture >= 0 && moisture <= 50) {
        if (sebum >= 0 && sebum <= 19) {
          recommendation = 'skintype_desc_advice_1';
        } else if (sebum >= 20 && sebum <= 30) {
          recommendation = 'skintype_desc_advice_2';
        } else if (sebum >= 31 && sebum <= 84) {
          recommendation = 'skintype_desc_advice_3';
        } else if (sebum > 84) {
          recommendation = 'skintype_desc_advice_4';
        }
      } else if (moisture > 50) {
        if (sebum >= 0 && sebum <= 19) {
          recommendation = 'skintype_desc_advice_8';
        } else if (sebum >= 20 && sebum <= 30) {
          recommendation = 'skintype_desc_advice_7';
        } else if (sebum >= 31 && sebum <= 84) {
          recommendation = 'skintype_desc_advice_6';
        } else if (sebum > 84) {
          recommendation = 'skintype_desc_advice_5';
        }
      }
    }
  }

  return recommendation;
};
