import { useHairAndScalpCondition } from './useHairAndScalpCondition';

import { Wrapper, ConditionContainer, Header, Text } from './style';

export const HairAndScalpCondition = () => {
  const { hairCondition, scalpCondition } = useHairAndScalpCondition();

  if (!hairCondition && !scalpCondition) return null;

  return (
    <Wrapper>
      {scalpCondition && (
        <ConditionContainer>
          <Header>Scalp Condition</Header>
          <Text>{scalpCondition}</Text>
        </ConditionContainer>
      )}

      {hairCondition && (
        <ConditionContainer>
          <Header>Hair Condition</Header>
          <Text>{hairCondition}</Text>
        </ConditionContainer>
      )}
    </Wrapper>
  );
};
