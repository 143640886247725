import { useAppDataContext } from 'utils/context';
import { useTranslation } from 'react-i18next';

import maleFace from 'images/face-male.png';
import femaleFace from 'images/face-female.png';

export const useFaceImage = () => {
  const { t } = useTranslation();
  const {
    appData: { gender },
  } = useAppDataContext();

  const imgUrl = gender === 'male' ? maleFace : femaleFace;
  const altText = `${gender} face`;

  const TZoneText = t('t_zone');
  const UZoneText = t('u_zone');

  return { imgUrl, altText, TZoneText, UZoneText };
};
