import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

export const ImageSliderContainer = styled.section`
  position: relative;
  margin: 0 auto;
  width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  font-size: 18px;

  @media ${breakpoints.laptop} {
    width: calc(640px / 1.5);
  }

  @media ${breakpoints.mobile} {
    width: calc(640px / 2.3);
    font-size: 14px;
  }
`;

export const TypeButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
`;

export const Button = styled.button`
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  font-size: inherit;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;

  &:hover {
    border-color: var(--color-main);
  }

  &:disabled {
    background-color: var(--color-main);
    color: white;
    cursor: auto;
  }
`;

export const CurrentImageContainer = styled.div<{ lorealLayout: string }>`
  position: relative;
  width: ${(props) => (props.lorealLayout === "true" ? "450px" : "100%")};
  height: ${(props) => (props.lorealLayout === "true" ? "410px" : "480px")};
  overflow: hidden;
  border-radius: ${(props) => (props.lorealLayout === "true" ? "50%" : "0")};
  border: ${(props) =>
    props.lorealLayout === "true" ? "2px solid gray" : "0"};

  @media ${breakpoints.laptop} {
    height: ${(props) =>
      props.lorealLayout === "true"
        ? "calc(465px / 1.5)"
        : "calc(480px / 1.5)"};
    width: ${(props) => (props.lorealLayout === "true" ? "335px" : "100%")};
  }

  @media ${breakpoints.mobile} {
    height: ${(props) =>
      props.lorealLayout === "true"
        ? "calc(465px / 2.3)"
        : "calc(480px / 2.3)"};
    width: ${(props) => (props.lorealLayout === "true" ? "235px" : "100%")};
  }
`;

export const ImagesList = styled.div<{
  currentImageIdx: number;
  lorealLayout: string;
}>`
  position: absolute;
  left: ${(props) =>
    props.lorealLayout === "true"
      ? `-${props.currentImageIdx * 520}px`
      : `-${props.currentImageIdx * 640}px`};
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: ${(props) => (props.lorealLayout === "true" ? "-37px" : "0")};
  transition: 0.3s ease;
  z-index: 2;

  @media ${breakpoints.laptop} {
    left: ${(props) =>
      props.lorealLayout === "true"
        ? `--${(props.currentImageIdx * 580) / 1.5}px`
        : `-${(props.currentImageIdx * 640) / 1.5}px`};
    margin-left: ${(props) => (props.lorealLayout === "true" ? "-26px" : "0")};
  }

  @media ${breakpoints.mobile} {
    left: ${(props) =>
      props.lorealLayout === "true"
        ? `--${(props.currentImageIdx * 620) / 2.3}px`
        : `-${(props.currentImageIdx * 640) / 2.3}px`};
    margin-left: ${(props) => (props.lorealLayout === "true" ? "-18px" : "0")};
  }
`;

export const AnalyzedImagesList = styled(ImagesList)<{
  showAnalyzedImages: boolean;
}>`
  z-index: ${(props) => (props.showAnalyzedImages ? 3 : 1)};
`;

export const Image = styled.img<{ lorealLayout: string }>`
  width: ${(props) => (props.lorealLayout === "true" ? "520px" : "640px")};
  height: 480px;
  background-color: rgba(0, 0, 0, 0.05);

  @media ${breakpoints.laptop} {
    width: ${(props) =>
      props.lorealLayout === "true"
        ? "calc(580px / 1.5)"
        : "calc(640px / 1.5)"};
    height: ${(props) =>
      props.lorealLayout === "true"
        ? "calc(540px / 1.5)"
        : "calc(480px / 1.5)"};
  }

  @media ${breakpoints.mobile} {
    width: ${(props) =>
      props.lorealLayout === "true"
        ? "calc(620px / 2.3)"
        : "calc(640px / 2.3)"};
    height: ${(props) =>
      props.lorealLayout === "true"
        ? "calc(540px / 2.3)"
        : "calc(480px / 2.3)"};
  }
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-top: 2px solid black;
    border-right: 2px solid black;
  }

  &:disabled {
    opacity: 0.3;
  }

  @media ${breakpoints.mobile} {
    display: none;
  }
`;

export const ArrowButtonLeft = styled(ArrowButton)`
  left: -60px;

  &::after {
    transform: rotate(-135deg);
  }
`;

export const ArrowButtonRight = styled(ArrowButton)`
  right: -60px;

  &::after {
    transform: rotate(45deg);
  }
`;

export const NoImage = styled.div`
  width: 640px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  @media ${breakpoints.laptop} {
    width: calc(640px / 1.5);
    height: calc(480px / 1.5);
  }

  @media ${breakpoints.mobile} {
    width: calc(640px / 2.3);
    height: calc(480px / 2.3);
  }
`;
