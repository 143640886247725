import { useHeader } from "./useHeader";

import { LorealHeader } from "./components/LorealTitle";

import {
  HeaderContainer,
  Filler,
  HeaderContent,
  UserDataContainer,
  CustomLogo,
} from "./style";

export const Header = () => {
  const { userDataInfo, Logo, isLorealHeader, customLogo } = useHeader();

  return (
    <HeaderContainer isLorealHeader={isLorealHeader}>
      <Filler />
      <HeaderContent>
        {customLogo ? (
          <CustomLogo
            src={customLogo}
            alt={"logo"}
          />
        ) : (
          <Logo />
        )}
        {isLorealHeader ? (
          <LorealHeader />
        ) : (
          <UserDataContainer>{userDataInfo}</UserDataContainer>
        )}
      </HeaderContent>
      <Filler />
    </HeaderContainer>
  );
};
