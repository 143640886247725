import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const Article = styled.article`
  text-align: center;
  position: relative;

  .mySwiper {
    width: 900px;
    padding-bottom: 30px;

    @media ${breakpoints.laptop} {
      width: 500px;
    }

    @media ${breakpoints.tablet} {
      width: 425px;
    }

    @media ${breakpoints.mobile} {
      width: 275px;
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-pagination-bullet {
      background: black;
    }
  }
`;

export const Heading = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #707070;
  margin-bottom: 20px;
`;

export const SlideContent = styled.div`
  margin: 0 auto;
  max-width: 550px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: grab;
`;

export const Image = styled.img`
  width: 250px;
  height: 250px;
  object-fit: contain;
  object-position: center;

  @media ${breakpoints.mobile} {
    width: 200px;
    height: 200px;
  }
`;

export const Type = styled.span`
  font-size: 16px;
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
`;

export const Description = styled.span`
  padding: 10px 0;
  text-align: center;
`;

export const Link = styled.a`
  margin-top: auto;
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s ease;

  :hover {
    background-color: #9e9e9e;
  }
`;
