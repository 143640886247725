import { Ring } from './style';

export const LoadingSpinner = () => {
  return (
    <Ring>
      <div />
      <div />
      <div />
      <div />
    </Ring>
  );
};
