import { useSkinAgeAndFocusArea } from './useSkinAgeAndFocusArea';

import { Wrapper, Container, Title, Value, Wrapp } from './style';

export const SkinAgeAndFocusArea = () => {
  const { skinAge = '', focusAreas = [] } = useSkinAgeAndFocusArea();

  return (
    <Wrapper>
      {skinAge && (
        <Container>
          <Title>Skin Age</Title>
          <Value>{skinAge}</Value>
        </Container>
      )}

      {focusAreas.length !== 0 && (
        <Container>
          <Title>Your Focus Area</Title>
          <Wrapp>
            <Value>{focusAreas[0]}</Value>
            <Value>{focusAreas[1]}</Value>
          </Wrapp>
        </Container>
      )}
    </Wrapper>
  );
};
