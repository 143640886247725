export const useHairAndScalpCondition = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const hairCondition = urlParams.get('hair_condition')?.split('_').join(' ');

  const scalpCondition = urlParams.get('scalp_condition')?.split('_').join(' ');

  return { hairCondition, scalpCondition };
};
