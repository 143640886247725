import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 70px;
  row-gap: 25px;

  @media ${breakpoints.mobile} {
    column-gap: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #e2e5e7;
  border-radius: 30px;

  @media ${breakpoints.mobile} {
    padding: 15px;
  }
`;

export const Wrapp = styled.div`
  display: flex;
  gap: 10px;
`;

export const Title = styled.span`
  font-size: 18px;

  @media ${breakpoints.mobile} {
    font-size: 12px;
  }
`;

export const Value = styled.span`
  font-size: 26px;
  font-weight: 600;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;
