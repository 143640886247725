import {
  DescriptionConainer,
  DescriptionMeasurement,
  DescriptionRate,
} from './style';

interface DescriptionProps {
  currentImage: any;
}

export const Description = ({ currentImage }: DescriptionProps) => {
  return (
    <DescriptionConainer>
      <DescriptionMeasurement>
        {currentImage.measurementText}
      </DescriptionMeasurement>
      <DescriptionRate color={currentImage.rateColor}>
        {currentImage.rateText}
      </DescriptionRate>
    </DescriptionConainer>
  );
};
