import { Title, SubTitle } from "./style";

export const LorealHeader = () => {
  return (
    <div>
      <Title>Your L’Oréal Professionnel My Scalp ID diagnosis</Title>

      <SubTitle>
        Get the best professional care for your hair and scalp concerns
      </SubTitle>
    </div>
  );
};
