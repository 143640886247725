import { APP_CUSTOMIZATION } from "constants/dict";
import { useAppDataContext } from "utils/context";
import { useTranslation } from "react-i18next";

export const useRadarChart = () => {
  const { t } = useTranslation();

  const {
    appData: { radars, appName },
  } = useAppDataContext();

  const radarColors = APP_CUSTOMIZATION[appName].radarColors;

  const circles = Object.values(radarColors)
    .map((color, idx) => ({
      width: 75 * (idx + 1),
      background: color,
    }))
    .reverse();
  const results = radars.map((result, idx) => {
    const { translationKey, isReversedBar, mapedValue, rate } = result;

    return {
      title: t(translationKey),
      dotPosition: isReversedBar ? 100 - mapedValue - 4 : mapedValue - 4,
      rotateDegree: (idx * 360) / radars.length,
      rate,
    };
  });

  const rates = Object.entries(radarColors).map(([rate, color]) => ({
    description: t(rate),
    color,
  }));

  return { circles, results, rates };
};
