import type { Product } from 'types/app';

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import {
  Article,
  Heading,
  SlideContent,
  Image,
  Type,
  Title,
  Description,
  Link,
} from './style';

interface CarouselProps {
  heading: string;
  items: Product[];
}

export const Carousel = ({ heading, items }: CarouselProps) => {
  if (!items.length) return null;

  return (
    <Article>
      <Heading>{heading}</Heading>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className='mySwiper'
        spaceBetween={20}
      >
        {items.map(({ name, image, concern, description, link }, idx) => (
          <SwiperSlide key={idx}>
            <SlideContent>
              <Image src={image} />
              <Type>{concern}</Type>
              <Title>{name}</Title>
              <Description>{description}</Description>
              {link && (
                <Link
                  href={link}
                  target='_blank'
                >
                  Learn more
                </Link>
              )}
            </SlideContent>
          </SwiperSlide>
        ))}
      </Swiper>
    </Article>
  );
};
