import { useAppDataContext } from 'utils/context';

export const useSkinType = () => {
  const {
    appData: { appName },
  } = useAppDataContext();

  const isRecommendation =
    appName === 'dermobella-skin' || appName === 'pierre-fabre-skin';

  return { isRecommendation };
};
