import { useTranslation } from 'react-i18next';
import { useAppDataContext } from 'utils/context';

export const useValuesTable = () => {
  const { t } = useTranslation();
  const {
    appData: {
      results: { sebumT, sebumU, moistureT, moistureU },
    },
  } = useAppDataContext();

  const tZoneTitle = t('t_zone');
  const uZoneTitle = t('u_zone');
  const moistureTitle = t('moisture');
  const sebumTitle = t('sebum');

  return {
    sebumT: sebumT?.value,
    sebumU: sebumU?.value,
    moistureT: moistureT?.value,
    moistureU: moistureU?.value,
    tZoneTitle,
    uZoneTitle,
    moistureTitle,
    sebumTitle,
  };
};
