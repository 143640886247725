import { useTranslation } from 'react-i18next';
import { useAppDataContext } from 'utils/context';

export const useThicknessBarGraph = () => {
  const { t } = useTranslation();
  const {
    appData: {
      results: { thickness },
    },
  } = useAppDataContext();

  const { mapedValue, rate, rateColor } = thickness;

  const rateText = t(rate);

  const rates = [
    'hair_thickness_desc_level_1',
    'hair_thickness_desc_level_2',
    'hair_thickness_desc_level_3',
  ].map((key) => t(key));
  const resultText = t('result');
  const title = t(thickness.translationKey);
  const bars = [...Array(6).keys()];

  return {
    resultText,
    rateText,
    rates,
    barWidth: mapedValue,
    title,
    bars,
    rateColor,
  };
};
