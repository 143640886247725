import { useProductRecommendation } from './useProductRecommendation';

import { Section } from './style';
import { Carousel } from './components/Carousel';

export const ProductRecommendation = () => {
  const { products, treatments } = useProductRecommendation();

  return (
    <Section>
      <Carousel
        heading='Product Recommendation'
        items={products}
      />
      <Carousel
        heading='Treatment Recommendation'
        items={treatments}
      />
    </Section>
  );
};
