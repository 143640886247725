import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1150px 1fr;
  justify-content: center;
  background-color: #ebebeb;

  @media ${breakpoints.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled.main`
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  grid-column: 2 / 3;
  background-color: white;

  @media ${breakpoints.laptop} {
    grid-column: 1 / -1;
    padding: 50px 15px;
  }
`;
