import { AppName, FormatedResults, Rate } from "types/app";
import { ResultResponseData } from "types/api";
import { mapValue } from "./mapValue";
import { TRANSLATION_KEYS, APP_CUSTOMIZATION } from "constants/dict";
import { getRate } from "./getRate";
import { RATE_COLORS } from "constants/dict";

interface formatResultsArgs {
  results: ResultResponseData[];
  appName: AppName;
}

export const formatResults = ({ results = [], appName }: formatResultsArgs) => {
  const formatedResults = results.reduce((prev, cur) => {
    const { measurement, value, avg_value } = cur;

    let translationKey = TRANSLATION_KEYS[measurement];
    let mapedValue: number = avg_value ? avg_value : value;
    const rateValue: number = mapedValue;
    let rate = "";
    let rateColor = "";
    let isReversedBar = false;

    const measurementsToMap = APP_CUSTOMIZATION[appName].measurements;

    // Map only measurements that have can be maped
    if (measurement in measurementsToMap) {
      mapedValue = mapValue({
        measurement,
        score: Math.round(mapedValue),
        appName,
      });
      rate = getRate({ measurement, value: rateValue, appName });
      rateColor = RATE_COLORS[rate as Rate];

      // Identify measurements that have reversed rates scale (the higher score - the better result)
      isReversedBar = measurementsToMap[measurement].very_poor[0] === 0;
    }

    // 'Skin' and 'Hair' apps both have 'keratin' measurement
    // but translations are different
    if (appName.includes("hair") && measurement === "keratin") {
      translationKey = TRANSLATION_KEYS.scalpKeratin;
    }

    // Thickness has only 3 stages
    if (measurement === "thickness") {
      const thicknessValue = avg_value ? avg_value : value;
      if (thicknessValue <= 39) {
        rate = "poor";
        mapedValue = (100 / 6) * 1;
        rateColor = RATE_COLORS.poor;
      } else if (thicknessValue >= 40 && thicknessValue <= 59) {
        rate = "moderate";
        mapedValue = (100 / 6) * 3;
        rateColor = RATE_COLORS.moderate;
      } else {
        rate = "good";
        mapedValue = (100 / 6) * 5;
        rateColor = RATE_COLORS.good;
      }
    }

    // For thickness Dermobella Hair uses value from 1 to 3
    if (appName === "dermobella-hair" && measurement === "thickness") {
      if (value === 1) {
        rate = "poor";
        mapedValue = (100 / 6) * 1;
        rateColor = RATE_COLORS.poor;
      } else if (value === 2) {
        rate = "moderate";
        mapedValue = (100 / 6) * 3;
        rateColor = RATE_COLORS.moderate;
      } else {
        rate = "good";
        mapedValue = (100 / 6) * 5;
        rateColor = RATE_COLORS.good;
      }
    }

    // 'hydration' has different rate translations
    if (measurement === "hydration") {
      if (rate === "excellent") {
        rate = "very_hydrated";
      } else if (rate === "good") {
        rate = "hydrated";
      } else if (rate === "moderate") {
        rate = "moderate";
      } else if (rate === "poor") {
        rate = "dehydrated";
      } else if (rate === "very_poor") {
        rate = "very_dehydrated";
      }
    }

    // 'sebum' has different rate translations
    if (measurement === "sebum") {
      if (rate === "excellent") {
        rate = "very_oily";
        rateColor = RATE_COLORS.very_poor;
      } else if (rate === "good") {
        rateColor = RATE_COLORS.moderate;
        rate = "oily";
      } else if (rate === "moderate") {
        rate = "normal";
        rateColor = RATE_COLORS.good;
      } else if (rate === "poor") {
        rate = "dry";
        rateColor = RATE_COLORS.moderate;
      } else if (rate === "very_poor") {
        rateColor = RATE_COLORS.very_poor;
        rate = "very_dry";
      }
    }

    if (appName === "dermopico-skin" && measurement === "impurity") {
      translationKey = "impurities";
    }

    return (prev = {
      ...prev,
      [measurement]: {
        ...cur,
        measurement,
        translationKey,
        mapedValue,
        rate,
        rateColor,
        isReversedBar,
      },
    });
  }, {}) as FormatedResults;

  return formatedResults;
};
