import { useAppDataContext } from 'utils/context';

export const useLayout = () => {
  const {
    isLoading,
    appData: { appName },
  } = useAppDataContext();

  const isSkinLayout = appName.includes('skin');
  const isLoreal = appName === 'loreal-hair';
  const isDermoPicoSkin = appName === 'dermopico-skin';

  return { isLoading, isSkinLayout, isLoreal, isDermoPicoSkin };
};
