import styled from "styled-components";

export const Title = styled.h2`
  font-size: 26px;
`;

export const SubTitle = styled.p`
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
`;
