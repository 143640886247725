import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const FaceImageContainer = styled.div`
  position: relative;
  width: 466px;
  height: 254px;

  @media ${breakpoints.mobile} {
    width: calc(466px / 1.5);
    height: calc(254px / 1.5);
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ZoneText = styled.span`
  position: absolute;
  top: 0;
  right: 100px;
  font-size: 16px;

  @media ${breakpoints.mobile} {
    right: 60px;
  }
`;

export const TZone = styled(ZoneText)``;

export const UZone = styled(ZoneText)`
  top: 145px;

  @media ${breakpoints.mobile} {
    top: 125px;
  }
`;
