import { getSkinType } from 'lib/getSkinType';
import { useTranslation } from 'react-i18next';
import { useAppDataContext } from 'utils/context';

export const useSkinTypeRate = () => {
  const { t } = useTranslation();
  const {
    appData: {
      results: { sebumT, sebumU },
    },
  } = useAppDataContext();

  const skinTypeTitle = t('skin_type');
  const skinTypeValue = t(getSkinType(sebumU?.value, sebumT?.value));

  return { skinTypeTitle, skinTypeValue };
};
