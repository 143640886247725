import { useFaceImage } from './useFaceImage';
import { FaceImageContainer, Image, UZone, TZone } from './style';

export const FaceImage = () => {
  const { imgUrl, altText, TZoneText, UZoneText } = useFaceImage();

  return (
    <FaceImageContainer>
      <Image src={imgUrl} alt={altText} />
      <TZone>{TZoneText}</TZone>
      <UZone>{UZoneText}</UZone>
    </FaceImageContainer>
  );
};
