import { useTranslation } from "react-i18next";
import { useAppDataContext } from "utils/context";

export const useTitle = (
  type: "analysis_result" | "overall_result" | "view_images",
) => {
  const { t } = useTranslation();

  const {
    appData: { appName },
  } = useAppDataContext();

  const isLoreal = appName === "loreal-hair";
  const isMainTitle = type === "analysis_result";
  const isNoTitle = isLoreal && isMainTitle;

  const title = t(type);

  return { title, isNoTitle };
};
