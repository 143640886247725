import { GlobalStyle } from 'utils/globalStyle';
import { AppDataContextProvider } from 'utils/context';
import { Layout } from 'components/Layout';

export const App = () => {
  return (
    <AppDataContextProvider>
      <GlobalStyle />
      <Layout />
    </AppDataContextProvider>
  );
};
