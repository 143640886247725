import styled from 'styled-components';

export const SkinTypeRateContainer = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-main);
  color: white;
  font-size: 18px;
  gap: 5px;
`;

export const Title = styled.span``;

export const Value = styled.span`
  text-transform: uppercase;
  font-weight: 700;
`;
