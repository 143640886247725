import { APP_CUSTOMIZATION } from 'constants/dict';
import { useAppDataContext } from 'utils/context';

export const useHeader = () => {
  const {
    appData: { name, surname, date, appName, gender, customization },
  } = useAppDataContext();

  const customLogo = customization.logo_url;
  const Logo = APP_CUSTOMIZATION[appName].logo;

  const isLorealHeader = appName === 'loreal-hair';

  const userData = [name, surname, gender, date];
  const userDataInfo = userData.filter((data) => data).join('-');

  return {
    userDataInfo,
    Logo,
    customLogo,
    isLorealHeader,
  };
};
