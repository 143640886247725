import { useTitle } from "./useTitle";
import { TitleContainer } from "./style";

export const Title = ({
  type,
}: {
  type: "analysis_result" | "overall_result" | "view_images";
}) => {
  const { title, isNoTitle } = useTitle(type);

  if (isNoTitle) return null;

  return <TitleContainer>{title}</TitleContainer>;
};
