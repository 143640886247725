import { useBarGraph } from './useBarGraph';
import { Result } from 'types/app';

import {
  BarGraphContainer,
  BarGraphHeader,
  BarGraphMeasurement,
  BarGraphValueContainer,
  BarGraphValueResult,
  BarGraphValueRate,
  RatesContainer,
  Rate,
  BarsContainer,
  Bar,
  ResultBar,
} from './style';

export const BarGraph = (result: Result) => {
  const {
    measurementText,
    rateText,
    rateColor,
    rates,
    bars,
    barWidth,
    resultText,
  } = useBarGraph(result);

  return (
    <BarGraphContainer>
      <BarGraphHeader>
        <BarGraphMeasurement>{measurementText}</BarGraphMeasurement>
        <BarGraphValueContainer>
          <BarGraphValueResult>{`${resultText} -`}</BarGraphValueResult>
          <BarGraphValueRate color={rateColor}>{rateText}</BarGraphValueRate>
        </BarGraphValueContainer>
      </BarGraphHeader>
      <RatesContainer>
        {rates.map((rate) => (
          <Rate key={rate}>{rate}</Rate>
        ))}
      </RatesContainer>
      <BarsContainer>
        {bars.map((bar) => (
          <Bar key={bar} />
        ))}
        <ResultBar width={barWidth} background={rateColor} />
      </BarsContainer>
    </BarGraphContainer>
  );
};
