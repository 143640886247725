import { useAppDataContext } from 'utils/context';

export const useFooter = () => {
  const {
    appData: { customization },
  } = useAppDataContext();

  const { address, email, phone } = customization;

  const contacts = [
    {
      title: 'Address',
      text: address,
    },
    {
      title: 'Email',
      text: email,
    },
    {
      title: 'Phone',
      text: phone,
    },
  ];

  return {
    contacts,
  };
};
