import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 70px;
  row-gap: 25px;
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
`;

export const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const Header = styled.h3`
  font-size: 26px;
`;

export const Text = styled.span`
  font-size: 20px;
  font-weight: 300;
  color: #505050;
  text-transform: lowercase;

  ::first-letter {
    text-transform: uppercase;
  }
`;
