import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const TitleContainer = styled.h2`
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  @media ${breakpoints.mobile} {
    font-size: 24px;
  }
`;
