import { useImageSider } from "./useImageSlider";

import {
  ImageSliderContainer,
  TypeButtonsContainer,
  Button,
  CurrentImageContainer,
  ImagesList,
  AnalyzedImagesList,
  Image,
  ArrowButtonLeft,
  ArrowButtonRight,
  NoImage,
} from "./style";

import { Description } from "./components/Description";
import { MeasurementButtons } from "./components/MeasurementButtons";

export const ImageSlider = ({ isloreal }: { isloreal: string }) => {
  const {
    images,
    currentImage,
    currentImageIdx,
    capturedButtonText,
    analyzedButtonText,
    showAnalyzedImages,
    onCapturedButtonClick,
    onAnalyzedButtonClick,
    onRightArrowButtonClick,
    onLeftArrowButtonClick,
    onMeasurementButtonClick,
  } = useImageSider();

  if (!images.length) return null;

  return (
    <ImageSliderContainer>
      <Description currentImage={currentImage} />
      <TypeButtonsContainer>
        <Button onClick={onCapturedButtonClick} disabled={!showAnalyzedImages}>
          {capturedButtonText}
        </Button>
        <Button onClick={onAnalyzedButtonClick} disabled={showAnalyzedImages}>
          {analyzedButtonText}
        </Button>
      </TypeButtonsContainer>
      <CurrentImageContainer lorealLayout={isloreal}>
        <ImagesList currentImageIdx={currentImageIdx} lorealLayout={isloreal}>
          {images.map((img) =>
            img.src ? (
              <Image
                lorealLayout={isloreal}
                key={img.measurementText}
                src={img.src}
                alt={img.measurementText}
              />
            ) : (
              <NoImage>No image</NoImage>
            )
          )}
        </ImagesList>
        <AnalyzedImagesList
          lorealLayout={isloreal}
          currentImageIdx={currentImageIdx}
          showAnalyzedImages={showAnalyzedImages}
        >
          {images.map((img) =>
            img.analyzedSrc ? (
              <Image
                lorealLayout={isloreal}
                key={img.measurementText}
                src={img.analyzedSrc}
                alt={img.measurementText}
              />
            ) : (
              <NoImage>No image</NoImage>
            )
          )}
        </AnalyzedImagesList>
      </CurrentImageContainer>
      <ArrowButtonLeft
        onClick={onLeftArrowButtonClick}
        disabled={currentImageIdx === 0}
      />
      <ArrowButtonRight
        onClick={onRightArrowButtonClick}
        disabled={currentImageIdx === images.length - 1}
      />
      <MeasurementButtons
        images={images}
        currentImageIdx={currentImageIdx}
        onMeasurementButtonClick={onMeasurementButtonClick}
      />
    </ImageSliderContainer>
  );
};
