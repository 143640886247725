import { AppName } from "types/app";
import { APP_NAME_KEYWORDS } from "constants/keywords";

const API_CONFIG: { [key in AppName]: string } = {
  "dermobella-skin": "3441/web-result/cndpskin",
  "dermobella-hair": "3331/web-result/cndphair",
  "dermopico-skin": "3771/web-result/cmaskin",
  "dermopico-hair": "3881/web-result/cmahair",
  "pierre-fabre-skin": "3441/web-result/cndpskin",
  "pierre-fabre-hair": "3331/web-result/cndphair",
  "morgan-hair": "3331/web-result/cndphair",
  "loreal-hair": "4002/web-results/cmahair",
};

export const getApiUrls = (isNewCrm: boolean) => {
  const { origin } = window.location;
  const isChinaDomain = origin.includes("china");

  const app = APP_NAME_KEYWORDS.find((name) => origin.includes(name));
  const type = origin.includes("hair") ? "hair" : "skin";
  let appName = `${app}-${type}` as AppName;
  // let appName = "dermobella-skin" as AppName;

  let GET_TOKEN_URL = `https://${
    isChinaDomain
      ? "1.116.243.170"
      : isNewCrm
      ? "v3-portal.chowis.cloud"
      : "v2-service.chowis.cloud"
  }/api/customers/generate_token`;

  let GET_CUSTOMER_URL = `https://${
    isChinaDomain
      ? "1.116.243.170"
      : isNewCrm
      ? "v3-portal.chowis.cloud"
      : "v2-service.chowis.cloud"
  }/api/customers`;

  if (appName === "loreal-hair") {
    GET_TOKEN_URL =
      "https://crm-loreal.chowis.cloud/api/customers/generate_token";
    GET_CUSTOMER_URL = "https://crm-loreal.chowis.cloud/api/customers";
  }

  const GET_RESULTS_URL = `https://${
    isChinaDomain ? "1.116.243.170" : "v2-api.chowis.cloud"
  }:${API_CONFIG[appName]}`;

  return {
    GET_TOKEN_URL,
    GET_CUSTOMER_URL,
    GET_RESULTS_URL,
    appName,
  };
};
