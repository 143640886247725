import { useFooter } from './useFooter';

import {
  FooterContainer,
  Filler,
  FooterContent,
  LogoContainer,
  Version,
  Contacts,
  Contact,
  ContactText,
  ContactTitle,
} from './style';

import { ReactComponent as LogoChowis } from 'images/logo-chowis.svg';

import { VERSION } from 'constants/version';

export const Footer = () => {
  const { contacts } = useFooter();

  return (
    <FooterContainer>
      <Filler />
      <FooterContent>
        <Contacts>
          {contacts.map(({ title, text }) => {
            return text ? (
              <Contact key={title}>
                <ContactTitle>{title}</ContactTitle>
                <ContactText>{text}</ContactText>
              </Contact>
            ) : null;
          })}
        </Contacts>
        <LogoContainer>
          <LogoChowis />
          <Version>{`v${VERSION}`}</Version>
        </LogoContainer>
      </FooterContent>
      <Filler />
    </FooterContainer>
  );
};
