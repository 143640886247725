import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const BarGraphContainer = styled.div`
  padding: 15px 50px;
  margin: 0 -50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f5f5f5;

  @media ${breakpoints.laptop} {
    margin: 0;
    padding: 15px;
  }
`;

export const BarGraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BarGraphMeasurement = styled.span`
  font-size: 18px;
  font-weight: 700;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const BarGraphValueContainer = styled.div`
  display: flex;
  gap: 5px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
`;

export const BarGraphValueResult = styled.span`
  font-size: 18px;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const BarGraphValueRate = styled.span<{ color?: string }>`
  color: ${(props) => props.color};
  font-size: 18px;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const RatesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
`;

export const Rate = styled.span`
  text-align: center;

  @media ${breakpoints.mobile} {
    font-size: 12px;
  }
`;

export const BarsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

export const Bar = styled.div`
  position: relative;
  height: 15px;
  border-right: 1px solid black;
  z-index: 1;

  :nth-child(2n) {
    &::after {
      position: absolute;
      right: -1px;
      top: -15px;
      content: '';
      display: block;
      width: 1px;
      height: 15px;
      border-right: 1px solid black;
    }
  }

  &:first-child {
    border-left: 1px solid black;

    &::after {
      position: absolute;
      left: -1px;
      top: -15px;
      content: '';
      display: block;
      width: 1px;
      height: 15px;
      border-right: 1px solid black;
    }
  }
`;

export const ResultBar = styled.div<{ width?: number; background?: string }>`
  position: absolute;
  width: ${(props) => `${props.width}%`};
  height: 15px;
  background: ${(props) => props.background};
`;
