import { Rate, Measurement, AppName } from 'types/app';
import {
  BAR_RATES,
  BAR_RATES_REVERSED,
  APP_CUSTOMIZATION,
} from 'constants/dict';

interface MapValueArgs {
  measurement: Measurement;
  score: number;
  appName: AppName;
}

export const mapValue = ({ measurement, score, appName }: MapValueArgs) => {
  try {
    const scoreRates = APP_CUSTOMIZATION[appName].measurements[measurement];
    const isReversedBarRates = scoreRates.very_poor[0] === 0;

    const [scoreRate, scoreBounds] = Object.entries(scoreRates).find(
      ([rate, [lowerBound, upperBound]]) =>
        score >= lowerBound && score <= upperBound
    ) as [Rate, [number, number]];

    const [barLowerBound, barUpperBound] = isReversedBarRates
      ? BAR_RATES_REVERSED[scoreRate]
      : BAR_RATES[scoreRate];
    const [scoreLowerBound, scoreUpperBound] = scoreBounds;
    const scoreRange = scoreUpperBound - scoreLowerBound;
    const barRange = barUpperBound - barLowerBound;

    const mapedValue =
      barLowerBound + ((score - scoreLowerBound) / scoreRange) * barRange;

    return Math.round(mapedValue);
  } catch {
    return 0;
  }
};
