import styled from 'styled-components';

export const MeasurementButtonsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

export const MeasurementButton = styled.button`
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  font-size: inherit;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;

  &:hover {
    border-color: var(--color-main);
  }

  &:disabled {
    background-color: var(--color-main);
    color: white;
    cursor: auto;
  }
`;
