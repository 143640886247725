import { useThicknessBarGraph } from './useThicknessBarGraph';

import {
  BarGraphContainer,
  BarGraphHeader,
  BarGraphMeasurement,
  BarGraphValueContainer,
  BarGraphValueResult,
  BarGraphValueRate,
  RatesContainer,
  Rate,
  BarsContainer,
  Bar,
  ResultBar,
} from './style';

export const ThicknessBarGraph = () => {
  const { barWidth, rates, rateText, resultText, title, bars, rateColor } =
    useThicknessBarGraph();

  return (
    <BarGraphContainer>
      <BarGraphHeader>
        <BarGraphMeasurement>{title}</BarGraphMeasurement>
        <BarGraphValueContainer>
          <BarGraphValueResult>{`${resultText} -`}</BarGraphValueResult>
          <BarGraphValueRate color={rateColor}>{rateText}</BarGraphValueRate>
        </BarGraphValueContainer>
      </BarGraphHeader>
      <RatesContainer>
        {rates.map((rate) => (
          <Rate key={rate}>{rate}</Rate>
        ))}
      </RatesContainer>
      <BarsContainer>
        {bars.map((bar) => (
          <Bar key={bar} />
        ))}
        <ResultBar
          width={barWidth}
          background={rateColor}
        />
      </BarsContainer>
    </BarGraphContainer>
  );
};
