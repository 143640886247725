export const getSkinType = (valueU: number, valueT: number) => {
  if (!valueT || !valueU) return '';

  if (valueT <= 5) {
    if (valueU <= 5) {
      return 'skintype_desc_level_1';
    } else if (valueU > 5 && valueU <= 19) {
      return 'skintype_desc_level_2';
    } else if (valueU > 19 && valueU <= 30) {
      return 'skintype_desc_level_3';
    } else if (valueU > 30 && valueU <= 100) {
      return 'skintype_desc_level_4';
    } else return 'skintype_desc_level_1';
  } else if (valueT > 5 && valueT <= 19) {
    if (valueU <= 5) {
      return 'skintype_desc_level_2';
    } else if (valueU > 5 && valueU <= 19) {
      return 'skintype_desc_level_2';
    } else if (valueU > 19 && valueU <= 30) {
      return 'skintype_desc_level_3';
    } else if (valueU > 30 && valueU <= 100) {
      return 'skintype_desc_level_4';
    } else return 'skintype_desc_level_2';
  } else if (valueT > 19 && valueT <= 30) {
    if (valueU <= 5) {
      return 'skintype_desc_level_2';
    } else if (valueU > 5 && valueU <= 19) {
      return 'skintype_desc_level_2';
    } else if (valueU > 19 && valueU <= 30) {
      return 'skintype_desc_level_3';
    } else if (valueU > 30 && valueU <= 100) {
      return 'skintype_desc_level_4';
    } else return 'skintype_desc_level_3';
  } else if (valueT > 30 && valueT <= 84) {
    if (valueU <= 5) {
      return 'skintype_desc_level_x';
    } else if (valueU > 5 && valueU <= 19) {
      return 'skintype_desc_level_x';
    } else if (valueU > 19 && valueU <= 30) {
      return 'skintype_desc_level_x';
    } else if (valueU > 30 && valueU <= 100) {
      return 'skintype_desc_level_4';
    } else return 'skintype_desc_level_4';
  } else if (valueT > 84 && valueT <= 100) {
    if (valueU <= 5) {
      return 'skintype_desc_level_x';
    } else if (valueU > 5 && valueU <= 19) {
      return 'skintype_desc_level_x';
    } else if (valueU > 19 && valueU <= 30) {
      return 'skintype_desc_level_x';
    } else if (valueU > 30 && valueU <= 84) {
      return 'skintype_desc_level_4';
    } else if (valueU > 84 && valueU <= 100) {
      return 'skintype_desc_level_5';
    } else return 'skintype_desc_level_5';
  } else {
    if (valueU <= 5) {
      return 'skintype_desc_level_1';
    } else if (valueU > 5 && valueU <= 19) {
      return 'skintype_desc_level_2';
    } else if (valueU > 19 && valueU <= 30) {
      return 'skintype_desc_level_3';
    } else if (valueU > 30 && valueU <= 84) {
      return 'skintype_desc_level_4';
    } else if (valueU > 84 && valueU <= 100) {
      return 'skintype_desc_level_5';
    } else return 'skintype_desc_level_5';
  }
};
