import { useState } from 'react';
import { useAppDataContext } from 'utils/context';
import { useTranslation } from 'react-i18next';
import { formatImageSrc } from 'lib/formatImageSrc';

export const useImageSider = () => {
  const {
    appData: { barGraphs, appName, results },
  } = useAppDataContext();
  const { t } = useTranslation();
  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const [showAnalyzedImages, setShowAnalyzedImages] = useState(false);

  const imagesToShow =
    appName === 'loreal-hair' && results.sebum
      ? [results.sebum, ...barGraphs]
      : barGraphs;

  const images = imagesToShow
    .map((result) => {
      const {
        translationKey,
        original_image_url,
        analyzed_image_url,
        rate,
        rateColor,
      } = result;

      const measurementText = t(translationKey);
      const rateText = t(rate);

      return {
        measurementText,
        rateColor,
        rateText,
        src: formatImageSrc(original_image_url),
        analyzedSrc: formatImageSrc(analyzed_image_url),
      };
    })
    .filter((image) => image.src);

  const onRightArrowButtonClick = () => setCurrentImageIdx(currentImageIdx + 1);
  const onLeftArrowButtonClick = () => setCurrentImageIdx(currentImageIdx - 1);
  const onMeasurementButtonClick = (idx: number) => setCurrentImageIdx(idx);

  const onCapturedButtonClick = () => setShowAnalyzedImages(false);
  const onAnalyzedButtonClick = () => setShowAnalyzedImages(true);

  const currentImage = images[currentImageIdx];
  const capturedButtonText = t('captured_image');
  const analyzedButtonText = t('analyzed_image');

  return {
    images,
    currentImage,
    currentImageIdx,
    capturedButtonText,
    analyzedButtonText,
    showAnalyzedImages,
    onRightArrowButtonClick,
    onLeftArrowButtonClick,
    onMeasurementButtonClick,
    onCapturedButtonClick,
    onAnalyzedButtonClick,
  };
};
