import styled from 'styled-components';

export const DescriptionConainer = styled.div`
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.1);
  font-size: inherit;
`;

export const DescriptionMeasurement = styled.span`
  font-weight: 700;
`;

export const DescriptionRate = styled.span<{ color?: string }>`
  color: ${(props) => props.color};
`;
