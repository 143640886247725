import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
`;

export const TableCell = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const LeftBottomBorderCell = styled(TableCell)`
  border-left: 1px dashed black;
  border-bottom: 1px dashed black;
`;

export const LeftBorderCell = styled(TableCell)`
  border-left: 1px dashed black;
`;

export const BottomBorderCell = styled(TableCell)`
  border-bottom: 1px dashed black;
`;
