import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const HeaderContainer = styled.header<{ isLorealHeader?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1150px 1fr;
  justify-content: center;
  background-color: ${(props) =>
    props.isLorealHeader ? 'white' : 'var(--color-main)'};
  color: ${(props) => (props.isLorealHeader ? 'black' : 'white')};

  @media ${breakpoints.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const Filler = styled.div`
  background-color: #ebebeb; ;
`;

export const HeaderContent = styled.div`
  padding: 20px;
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  justify-items: center;
  align-items: center;
  gap: 10px;

  @media ${breakpoints.laptop} {
    grid-column: 1 / 2;
  }
`;

export const UserDataContainer = styled.span`
  height: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 30px;

  @media ${breakpoints.mobile} {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const CustomLogo = styled.img`
  max-width: 200px;
  height: auto;
  max-height: 100px;
  object-position: center;
  object-fit: contain;
`;
