import { AppName, Measurement, Rate } from 'types/app';
import { APP_CUSTOMIZATION } from 'constants/dict';

interface GetRateArgs {
  measurement: Measurement;
  value: number;
  appName: AppName;
}

export const getRate = ({ measurement, value, appName }: GetRateArgs) => {
  const scoreRates = APP_CUSTOMIZATION[appName].measurements[measurement];

  const rate = Object.entries(scoreRates).find(
    ([key, [lowerBound, upperBound]]) =>
      value >= lowerBound && value <= upperBound
  ) || ['moderate'];

  return rate[0] as Rate;
};
