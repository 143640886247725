import { Result } from 'types/app';
import { useTranslation } from 'react-i18next';
import {
  BAR_KEYWORDS,
  SCALP_SEBUM_KEYWORDS,
  SCALP_HYDRATION_KEYWORDS,
} from 'constants/keywords';

export const useBarGraph = (result: Result) => {
  const { t } = useTranslation();

  const { mapedValue, rate, rateColor, isReversedBar } = result;

  const measurementText = t(result.translationKey);
  const rateText = t(rate);

  let rateKeys = BAR_KEYWORDS;

  if (result.measurement === 'sebum') {
    rateKeys = SCALP_SEBUM_KEYWORDS;
  } else if (result.measurement === 'hydration') {
    rateKeys = SCALP_HYDRATION_KEYWORDS;
  }

  const rates = rateKeys.map((rate) => t(rate));

  const bars = [...Array(10).keys()];
  const barWidth = isReversedBar ? mapedValue : 100 - mapedValue;

  const resultText = t('result');

  return {
    measurementText,
    rateText,
    rateColor,
    rates,
    bars,
    barWidth,
    resultText,
  };
};
