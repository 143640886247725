export const windowSizes = {
  mobile: 600,
  tablet: 800,
  laptop: 1160,
};

export const breakpoints = {
  mobile: `(max-width: ${windowSizes.mobile}px)`,
  tablet: `(max-width: ${windowSizes.tablet}px)`,
  laptop: `(max-width: ${windowSizes.laptop}px)`,
};
