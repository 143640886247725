import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1150px 1fr;
  justify-content: center;
  background-color: var(--color-main);

  @media ${breakpoints.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const Filler = styled.div`
  background-color: #e9e9e9;
`;

export const FooterContent = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  grid-column: 2 /3;
  color: white;

  @media ${breakpoints.laptop} {
    grid-column: 1 / -1;
  }
`;

export const Contacts = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 100px;
  row-gap: 25px;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ContactTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
`;

export const ContactText = styled.span`
  font-size: 16px;
  font-weight: 300;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Version = styled.span``;
