import { useRecommendationTable } from './useRecommendationTable';
import { Table, TZoneTitle, TZoneVale, UZoneTitle, UZoneVale } from './style';

export const RecommendationTable = () => {
  const { tZone, uZone, tZoneTitle, uZoneTitle } = useRecommendationTable();

  if (!tZone || !uZone) return null;

  return (
    <Table>
      <TZoneTitle>{tZoneTitle}</TZoneTitle>
      <TZoneVale>{tZone}</TZoneVale>
      <UZoneTitle>{uZoneTitle}</UZoneTitle>
      <UZoneVale>{uZone}</UZoneVale>
    </Table>
  );
};
