import { useValuesTable } from './useValuesTable';

import {
  Table,
  TableCell,
  LeftBottomBorderCell,
  LeftBorderCell,
  BottomBorderCell,
} from './style';

export const ValuesTable = () => {
  const {
    sebumT,
    sebumU,
    moistureT,
    moistureU,
    tZoneTitle,
    uZoneTitle,
    sebumTitle,
    moistureTitle,
  } = useValuesTable();

  let isRender = true;
  const results = [sebumT, sebumU, moistureT, moistureU];
  results.forEach((result) => {
    if (!result || result <= 0) {
      isRender = false;
    }
  });

  if (!isRender) return null;

  return (
    <Table>
      <BottomBorderCell />
      <LeftBottomBorderCell>{moistureTitle}</LeftBottomBorderCell>
      <LeftBottomBorderCell>{sebumTitle}</LeftBottomBorderCell>
      <BottomBorderCell>{tZoneTitle}</BottomBorderCell>
      <LeftBottomBorderCell>{moistureT}</LeftBottomBorderCell>
      <LeftBottomBorderCell>{sebumT}</LeftBottomBorderCell>
      <TableCell>{uZoneTitle}</TableCell>
      <LeftBorderCell>{moistureU}</LeftBorderCell>
      <LeftBorderCell>{sebumU}</LeftBorderCell>
    </Table>
  );
};
