import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const SkinTypeContainer = styled.section`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 50px;

  @media ${breakpoints.laptop} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
