import {
  CoordinateChartContainer,
  AbscissaTitles,
  OrdinateTitles,
  MainTitle,
  ChartFiller,
  OptimalChartFiller,
  UZone,
  TZone,
  UPoint,
  TPoint,
} from './style';
import { useCoordinateChart } from './useCoordinateChart';

export const CoordinateChart = () => {
  const {
    sebumT = 0,
    sebumU = 0,
    moistureT = 0,
    moistureU = 0,
    translation,
  } = useCoordinateChart();

  return (
    <CoordinateChartContainer
      abscissaTitle='sebum'
      ordinateTitle='moisture'
    >
      <AbscissaTitles>
        <span>{translation.low}</span>
        <MainTitle>{translation.sebum}</MainTitle>
        <span>{translation.high}</span>
      </AbscissaTitles>
      <OrdinateTitles>
        <span>{translation.dehydrated}</span>
        <MainTitle>{translation.moisture}</MainTitle>
        <span>{translation.excellent}</span>
      </OrdinateTitles>
      <ChartFiller />
      <ChartFiller />
      <ChartFiller />
      <OptimalChartFiller />
      <ChartFiller />
      <ChartFiller />
      <UZone
        width={moistureU}
        height={sebumU}
      >
        <UPoint>U</UPoint>
      </UZone>
      <TZone
        width={moistureT}
        height={sebumT}
      >
        <TPoint>T</TPoint>
      </TZone>
    </CoordinateChartContainer>
  );
};
