import { useRadarChart } from "./useRadarChart";

import {
  RadarChartContainer,
  RadarContainer,
  Circle,
  Divider,
  Dot,
  Title,
  RatesList,
  RateContainer,
  RateColor,
  RateDescription,
} from "./style";

export const RadarChart = () => {
  const { circles, results, rates } = useRadarChart();
  return (
    <RadarChartContainer>
      <RadarContainer>
        {circles.map((circle) => (
          <Circle key={circle.background} {...circle} />
        ))}
        {results.map((result) => (
          <Divider key={result.title} rotateDegree={result.rotateDegree}>
            <Dot position={result.dotPosition} />
            <Title rate={result.rate} rotateDegree={-result.rotateDegree}>
              {result.title}
            </Title>
          </Divider>
        ))}
      </RadarContainer>
      <RatesList>
        {rates.map((rate) => (
          <RateContainer key={rate.description}>
            <RateColor background={rate.color} />
            <RateDescription>{rate.description}</RateDescription>
          </RateContainer>
        ))}
      </RatesList>
    </RadarChartContainer>
  );
};
