import { useAppDataContext } from 'utils/context';
import { useTranslation } from 'react-i18next';

export const useCoordinateChart = () => {
  const {
    appData: { results, consultant_company_id },
  } = useAppDataContext();
  const { t } = useTranslation();
  const { sebumT, sebumU, moistureT, moistureU } = results;

  const isGermaine = consultant_company_id === '238';

  const translation = {
    sebum: t('sebum'),
    moisture: t('moisture'),
    low: t('low'),
    high: t('high'),
    dehydrated: t('dehydrated'),
    excellent: isGermaine ? t('excellent') : t('normal'),
  };

  return {
    sebumT: sebumT?.value,
    sebumU: sebumU?.value,
    moistureT: moistureT?.value,
    moistureU: moistureU?.value,
    translation,
  };
};
