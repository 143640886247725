export const BAR_KEYWORDS = [
  'very_poor',
  'poor',
  'moderate',
  'good',
  'excellent',
];

export const SCALP_SEBUM_KEYWORDS = [
  'very_dry',
  'dry',
  'normal',
  'oily',
  'very_oily',
];

export const SCALP_HYDRATION_KEYWORDS = [
  'very_dehydrated',
  'dehydrated',
  'moderate',
  'hydrated',
  'very_hydrated',
];

export const APP_NAME_KEYWORDS = [
  'dermobella',
  'dermopico',
  'pierre-fabre',
  'morgan',
  'loreal',
] as const;
