import type { Product } from 'types/app';

import { SCALP_CONDITIONS, HAIR_CONDITIONS } from 'constants/dict';

import { fetchProductRecommendation } from 'api/fetchProductRecommendation';

import { useEffect, useState } from 'react';

export const useProductRecommendation = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [treatments, setTreatments] = useState<Product[]>([]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const hairCondition = urlParams.get(
    'hair_condition'
  ) as keyof typeof HAIR_CONDITIONS;

  const scalpCondition = urlParams.get(
    'scalp_condition'
  ) as keyof typeof SCALP_CONDITIONS;

  const hairConditionId = HAIR_CONDITIONS[hairCondition];
  const scalpConditionId = SCALP_CONDITIONS[scalpCondition];

  useEffect(() => {
    const fetchData = async () => {
      const { Products, Treatments } = await fetchProductRecommendation({
        hairConditionId,
        scalpConditionId,
      });
      setProducts(Products);
      setTreatments(Treatments);
    };

    fetchData();
  }, [hairConditionId, scalpConditionId]);

  return { products, treatments };
};
