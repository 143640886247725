import { LayoutContainer, ContentContainer, LoadingContainer } from "./style";
import { useLayout } from "./useLayout";

import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Title } from "components/Title";
import { SkinType } from "components/SkinType";
import { BarGraphs } from "components/BarGraphs";
import { RadarChart } from "components/RadarChart";
import { ImageSlider } from "components/ImageSlider";
import { ProductRecommendation } from "components/ProductRecommendation";
import { HairAndScalpCondition } from "components/HairAndScalpCondition";
import { SkinAgeAndFocusArea } from "components/SkinAgeAndFocusArea";

export const Layout = () => {
  const { isLoading, isSkinLayout, isLoreal, isDermoPicoSkin } = useLayout();

  return isLoading ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <>
      <Header />
      <LayoutContainer>
        <ContentContainer>
          <Title type="analysis_result" />
          {isDermoPicoSkin && <SkinAgeAndFocusArea />}
          {isSkinLayout && <SkinType />}
          {isLoreal && <HairAndScalpCondition />}
          <BarGraphs />
          <Title type="overall_result" />
          <RadarChart />
          <Title type="view_images" />
          <ImageSlider isloreal={String(isLoreal)} />
          {isLoreal && <ProductRecommendation />}
        </ContentContainer>
      </LayoutContainer>
      <Footer />
    </>
  );
};
