import { useSkinTypeRate } from './useSkinTypeRate';
import { SkinTypeRateContainer, Title, Value } from './style';

export const SkinTypeRate = () => {
  const { skinTypeTitle, skinTypeValue } = useSkinTypeRate();

  if (!skinTypeValue) return null;

  return (
    <SkinTypeRateContainer>
      <Title>{`${skinTypeTitle} - `}</Title>
      <Value>{skinTypeValue}</Value>
    </SkinTypeRateContainer>
  );
};
